.skills-card {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  @extend .box-stats;
}

.brand {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5rem;
  height: 5rem;
  margin: 0 1rem;
}
.brand__img {
  width: 4rem;
}
